<template>
  <div class="container">
    <div class="content">
      <img class="logo" src="../../assets/images/pc/login-logo-icon.png" />
      <!--img class="close-btn" src="../../assets/images/pc/close-icon.png" @click="$router.go(-1)" /-->
      <div class="title-item">手机号登录</div>
      <div class="input-item">
        <input v-model="phone" maxlength="11" placeholder="输入注册偶遇圈手机号" />
      </div>
      <div class="input-item">
        <input v-model="vcode" placeholder="验证码" />
        <div class="code-btn" :class="phone ? '' : 'not'" @click="handleSendsms">{{ time > 0 ? time + 's' : '获取验证码' }}</div>
      </div>
      <div class="login-btn" :class="phone && vcode ? '' : 'not'" @click="handleLogin">开始偶遇</div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import { sendsms, login, userInfo } from '@/api/index'
export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      vcode: '',
      time: 0,
      submit: false
    }
  },
  computed: {
    ...mapState(['token'])
  },
  methods: {
    ...mapMutations(['SET_TOKEN', 'SET_RANT', 'SET_USERINFO']),
    handleGetUserInfo () {
      userInfo().then(res => {
        if (res.error === 0) {
          this.SET_USERINFO(res.info)
          this.$dialog.alert({
            message: '登录成功'
          }).then(() => {
            this.$router.go(-1)
          })
        } else {
          this.SET_TOKEN('')
          this.$dialog.alert({
            message: '登录失败, 请重试'
          })
        }
      })
    },
    handleSendsms () {
      const { phone, time, submit } = this
      if (time > 0 || submit) {
        return
      }
      const reg = /^1\d{10}$/
      if (!reg.test(phone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      this.submit = true
      sendsms({
        phone
      }).then(res => {
        this.submit = false
        if (res.error === 0) {
          let time = 60
          this.time = time
          const timer = setInterval(() => {
            time--
            this.time = time
            if (time <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$toast(res.info)
        }
      })
    },
    handleLogin () {
      const { phone, vcode, submit } = this
      if (submit) {
        return
      }
      const reg = /^1\d{10}$/
      if (!reg.test(phone)) {
        this.$toast('请输入正确的手机号')
        return
      }
      if (!vcode || vcode.length !== 4) {
        this.$toast('请输入正确的验证码')
        return
      }
      this.submit = true
      login({
        phone,
        vcode,
        device: 3,
        is_from: 4
      }).then(res => {
        console.log(res)
        this.submit = false
        if (res.error === 0) {
          this.SET_TOKEN(res.info)
          this.SET_RANT(res.rant)
          this.handleGetUserInfo()
        } else {
          this.$dialog.alert({
            message: res.info
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #000;
  .content {
    width: 424px;
    height: 338px;
    background-color: #fff;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -168px 0 0 -212px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo {
      width: 156px;
      height: 77px;
      position: absolute;
      top: -18px;
      left: 50%;
      margin-left: -78px;
    }
    .close-btn {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 16px;
      right: 22px;
      cursor: pointer;
    }
    .title-item {
      margin-top: 48px;
      margin-bottom: 30px;
      font-size: 20px;
      color: #252525;
    }
    .input-item {
      padding-left: 50px;
      width: 268px;
      height: 42px;
      border-radius: 21px;
      background-color: #f3f3f3;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        flex: 1;
        height: 100%;
        font-size: 14px;
      }
      .code-btn {
        width: 90px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background-color: #010101;
        border-radius: 18px;
        color: #fff;
        font-size: 12px;
        margin-right: 3px;
        cursor: pointer;
        &.not {
          background-color: #bfbfbf;
          cursor: not-allowed;
        }
      }
    }
    .login-btn {
      width: 164px;
      height: 42px;
      border-radius: 21px;
      background-color: #000;
      color: #fff;
      border-radius: 21px;
      line-height: 42px;
      text-align: center;
      font-size: 15px;
      cursor: pointer;
      &.not {
        background-color: #bfbfbf;
        cursor: not-allowed;
      }
    }
  }
}
</style>
